import { inject, Injectable } from '@angular/core';
import { EtlService } from '@services/etl.service';
import { StorageType, Store } from '@services/store';
import { EtlRoute } from '@shared/models/etl-route.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EtlRoutesStore extends Store<EtlRoute[]> {
  protected static storageType: StorageType = 'session';
  private readonly service = inject(EtlService);

  protected getDataSource(key: string): Observable<EtlRoute[]> {
    return this.service.getRoutes(key as any);
  }
}
