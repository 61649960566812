<div class="d-flex justify-content-between p-3 privacyPageHeader">
  <div>
    <h3 class="d-none d-md-block mb-0 align-self-center" data-cy="pageTitle">Brightscrip Privacy Policy</h3>
    <h4 class="d-block d-md-none mb-0 align-self-center" data-cy="pageTitleMobile">Brightscrip Privacy Policy</h4>
    <span class="d-block d-md-none">Effective date: October 3, 2018</span>
  </div>
  <div class="d-none d-md-block text-center mt-1">Effective date: October 3, 2018</div>
  <div class="d-flex justify-content-end">
    <chitin-button label="Print" (clicked)="printDiv()" icon="fa-solid fa-print"></chitin-button>
  </div>
</div>

<div class="card m-2 m-md-4 mt-2">
  <div class="card-body privacyCardBody" id="privacy">
    <ng-container *ngTemplateOutlet="policy"></ng-container>
  </div>
</div>

<ng-template #policy>
  <p class="fw-bold">Purpose:</p>

  <p>This privacy policy applies to www.brightscrip.com which is owned and operated by Monarch Specialty Group (hereinafter “Monarch”).</p>

  <p>
    Monarch respects the privacy of anyone who uses the Brightscrip website (<a href="https://www.brightscrip.com" target="_blank"
      >www.brightscrip.com</a
    >) or the Brightscrip Service. Brightscrip publishes this Privacy Policy to communicate how we gather and use information about you and
    how we protect it. It also describes the choices available to you regarding our use of your personal information and how you can access
    and update this information.
  </p>

  <p>
    If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact
    <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a>.
  </p>

  <p class="fw-bold">Information Related to Data Collected for our Customers</p>

  <p>
    Brightscrip collects information under the direction of its pharmaceutical manufacturer customers (hereinafter “Customer” or
    “Customers”), and has no direct relationship with the individuals whose data it processes. If you are a user of one of our Customers and
    would no longer like to be contacted by one of our Customers that use our service, please contact the Customer that you interact with
    directly. We may transfer information to companies that help us provide our service. Transfers to subsequent third parties are covered
    by the service agreements with our Customers.
  </p>

  <p>Brightscrip acknowledges that you have the right to access your personal information.</p>

  <p>
    Brightscrip has no direct relationship with the individuals whose personal data it processes. An individual who seeks access, or who
    seeks to correct, amend, or delete inaccurate data should direct the query to our Customer (the data controller). If the Customer
    requests Brightscrip to remove the data, we will respond to their request within 24 hours.
  </p>

  <p>
    We will retain data we process on behalf of our Customers for as long as needed to provide services to our Customer. Brightscrip will
    retain and use this information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
  </p>

  <p class="fw-bold">Information Collected</p>

  <p>
    We require users of the Brightscrip service (hereinafter “Service”) to give us contact information, such as their name, company name,
    address, phone number, and email address. At the time you register for the Service, we may also ask for additional personal and company
    information, such as your pharmacy NCPDP number. When you use our Services, we may automatically collect information on the type of
    device you use and the operating system version.
  </p>

  <p>
    Brightscrip uses the information collected to provision and deliver the Service. Other than in connection with provision of the Service,
    users can opt-out of being contacted by us, or receiving such information from us, at any time by sending an email to
    <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a>.
  </p>

  <p>
    Except as stated at the time we request information, or as contemplated in this policy or required by law, we do not disclose any
    personal information (including, without limitation, email addresses) that is provided by users to third parties. We will share your
    personal information with third parties only in the ways that are described in this privacy policy. We do not sell your personal
    information to third parties.
  </p>

  <p>
    Brightscrip may employ other companies and people to perform tasks on our behalf and need to share user information with them to provide
    the Services. Examples may include provisioning of prescription information, processing of prescription claims transactions, fulfilling
    copay program request orders, sending emails, and providing customer service. In this case, these companies shall be authorized to use
    your personal information only as necessary to provide these services to us, activities of which shall be covered by the service
    agreements with our Customers.
  </p>

  <p>
    We may utilize a tool that enables us to monitor user inputs (which includes mouse and keyboard actions), but we will only save
    information regarding the time and date of such inputs and the related input field. In addition, as part of the registration process, we
    will collect and store information filled out by you in the registration form, which may be later retrieved in case that the
    registration process was interrupted and needs to be reinitiated. We may also use this information to contact you for technical
    assistance reasons and/or service purposes.
  </p>

  <p>
    Some of our pages may utilize framing techniques to serve content from our Clients or partners while preserving the look and feel of our
    site. In the event of this instance, please be aware that you would be providing your personal information to these third parties and
    not to
    <a href="https://www.brightscrip.com" target="_blank">www.brightscrip.com</a>.
  </p>

  <p class="fw-bold">Protection of Company and Others</p>

  <p>
    Brightscrip may release information when we believe in good faith that release is necessary as required by law, such as to comply with a
    subpoena, or similar legal process; enforce or apply our conditions of use and other agreements; or protect the rights, property, or
    safety of Monarch, our employees, our users, or others. This includes exchanging information with other companies and organizations for
    fraud protection or other examples.
  </p>

  <p>
    We may choose to buy or sell assets during the normal course of business. User information can be one of the assets that are
    transferred. Moreover, if Monarch or substantially all of its relevant assets, were acquired by merger, consolidation, sale of assets or
    otherwise, customer information contained by brightscrip would be one of the assets that would be transferred. You will be notified via
    email or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you
    may have regarding your personal information.
  </p>

  <p class="fw-bold">User Data</p>

  <p>
    Brightscrip structurally stores all user Data and information through its US-based data centers, including all failovers. Brightscrip
    will not review, share, distribute, print, or reference any user data except as provided in this policy or as may be required by law,
    and except for the purposes of deploying, supporting or enhancing the Service. Users are responsible for maintaining the confidentiality
    and security of their user registration and password.
  </p>

  <p>
    Brightscrip may also be required to disclose an individual’s personal information in response to a lawful request by public authorities,
    including to meet law enforcement requirements. In this case, Brightscrip may be subject to the investigative and enforcement powers of
    the Federal Drug Administration (FDA), U.S. Centers for Medicare & Medicaid Services or the Federal Trade Commission (FTC).
  </p>

  <p>
    Users who are Covered Entities or Business Associates under the Health Insurance Portability and Accountability Act of 1996 as amended
    (“HIPAA”) must agree to comply with the HIPAA Privacy and Security Rules, including without limitation the Minimum Necessary Rule at 45
    CFR 164.502(b). While Brightscrip has put safeguards in place consistent with those required by the HIPAA Privacy and Security Rules,
    because of the nature of Brightscrip service, Brightscrip does not expect to receive any Protected Health Information (PHI) from Covered
    Entities or Business Associates.
  </p>

  <p>
    Brightscrip may provide the ability for users to request additional information or contact Brightscrip. We collect certain information
    from visitors such as their name, phone number and email and the purpose of their request or interest. This information is captured in
    our systems to better serve our users and visitor needs. We also capture log information from our servers to help diagnose technical
    problems, and to constantly improve the quality of the Service. We may also track and analyze non-identifying and aggregate usage and
    volume statistical information from our website visitors and customers and provide such information to third parties.
  </p>

  <p class="fw-bold">User Access and Choice</p>

  <p>
    Upon request Brightscrip will provide the user with information about whether we hold any customer personal information. If such
    personal information changes, or if the user no longer desires our service, the user may update, change, amend and delete/remove
    registration information by going to the user or company profile record under the setup/administration section. If you need assistance
    in correcting, updating or deleting your information, please send your request to
    <a href="mailto:info@brightscrip.com">info&#64;brightscrip.com</a> or contact us by telephone or postal mail at the contact information
    listed below. Brightscrip will respond to your correction or update request within at most 30 days from the date of your request.
  </p>

  <p>
    We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your
    information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements
  </p>

  <p>
    If you wish to subscribe to our newsletters, we will use your name and email address to send the newsletter to you. Out of respect for
    your privacy, you may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in
    these emails, accessing the email preferences in your account settings page or you can contact us at
    <a href="mailto:info@brightscrip.com">info&#64;brightscrip.com</a>.
  </p>

  <p>
    We may provide you the opportunity to participate in surveys on our site. If you participate, we will request certain personally
    identifiable information from you. Participation in these surveys is completely voluntary and you therefore have a choice whether or not
    to disclose this information. The requested information typically includes contact information (such as name and address), and
    demographic information (such as zip code). We use this information to improve our services. We may use a third-party service provider
    to conduct surveys; that company will be prohibited from using our users’ personal information for any other purpose.
  </p>

  <p class="fw-bold">Cookies and Other Tracking Technologies</p>

  <p>
    When you are using the Service we strive to make the service easy to use and valuable to you and your company. Brightscrip and our
    partners may use cookies or similar technologies in analyzing trends, administering the site, tracking users’ movements around the site
    and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by
    these companies on an individual as well as aggregated basis.
  </p>

  <p>
    We may use cookies for other purposes, such as to remember users’ settings (e.g. language preference), for authentication. Users can
    control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use
    some features or areas of our site may be limited.
  </p>

  <p>
    As is true of most websites, we gather certain information automatically and store it in log files. This information may include
    internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time
    stamp, and/or clickstream data. We may combine this automatically collected log information with other information we collect about you.
    We do this to improve services we offer you, to improve analytics, or site functionality.
  </p>

  <p>
    Further to the above, we may use mobile analytics software to allow us to better understand the functionality of our Mobile Software on
    your phone. This software may record information such as how often you use the application, the events that occur within the
    application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store
    within the analytics software to any personally identifiable information you submit within the mobile application.
  </p>

  <p class="fw-bold">Third-Party Sites</p>

  <p>
    Brightscrip may contain links to other websites. Brightscrip is not responsible for the privacy practices or the content of these other
    websites. Customers and visitors will need to check the policy statement of these other websites to understand their policies and how
    they protect their privacy. Customers and visitors who access a linked site may be disclosing their private information. It is the
    responsibility of the user to keep such information private and confidential.
  </p>

  <p class="fw-bold">Security</p>

  <p>
    Brightscrip utilizes some of the most advanced technology for Internet security available today. When accessing our site using a common
    web browser, Transport Layer Security (TLS) technology is used across various parts of the site and protects your information using both
    server authentication and data encryption, ensuring that your data is safe and secure.
  </p>

  <p>
    We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive
    it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot
    guarantee its absolute security. If you have any questions about security on our Website, you can contact us at
    <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a>.
  </p>

  <p>
    Brightscrip also provides each user in your organization with a unique password that must be entered each time a user logs into the
    Service. As stated above, Brightscrip is using a session “cookie” to store user sessions and provide an easy to use experience. Cookies
    do not include either the username or password of the user, or any other confidential user and session information.
  </p>

  <p>
    Brightscrip is hosted in a security-oriented server environment that uses a firewall and other advanced technology to prevent
    interference or access from outside intruders.
  </p>

  <p class="fw-bold">Opt-Out Policy</p>

  <p>
    Brightscrip offers its users a means to choose how we may use information provided. If, at any time after registering for information or
    ordering the Service, you change your mind about receiving information from us, send us a request specifying your new choice. Simply
    send your request to
    <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a>.
  </p>

  <p class="fw-bold">Changes to this Policy</p>

  <p>
    Brightscrip will update this policy promptly if and when any of the policies described here changes. When we make material changes to
    this policy, we will post an announcement on our website with details prior to the change becoming effective. We encourage you to
    periodically review this page for the latest information on our privacy practices.
  </p>

  <p class="fw-bold">Privacy Complaints</p>

  <p>
    Brightscrip commits to resolve complaints about your privacy and our collection or use of your personal information. Individuals with
    inquiries or complaints regarding this privacy policy should first contact Monarch at:
  </p>

  <div>
    <p>
      Email: <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a><br />
      Physical Address:<br /><br />
      211 South Clark Street<br />
      P.O. Box 310 <br />
      Chicago, IL 60690
    </p>
  </div>

  <p class="fw-bold">Additional Information:</p>

  <p>
    Questions regarding this Statement or the practices of this site should be directed to the Brightscrip support team by sending your
    questions to
    <a href="mailto:info@monarchsp.com">info&#64;monarchsp.com</a>.
  </p>
</ng-template>
